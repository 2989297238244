import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
// @ts-ignore
import { graphql, PageProps, withPrefix } from "gatsby";
import Helmet from "react-helmet";
import HeroBanner from "../../components/HeroBanner";
import { Link } from "../../components/Link";
import ReceiptData from "../../components/ReceiptData";
import Section from "../../components/Section";
import SEO from "../../components/Seo";
import SignUpForm from "../../components/SignUpForm";
import StructuredData from "../../components/StructuredData";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { orderStatus } from "../../services/salesforce/OrderStatus";
import { LocalisedPageContext } from "../../types/PageTypes";
import { ImageInterface, LocalizedContextInterface, PortableText, SanityColorList, Seo } from "../../types/SanityTypes";
import { getLocalizedPath } from "../../utils/gatsbyBuild";
import "./styles.scss";
import { deleteLocalStorageOrderItems } from "../../services/salesforce/helper";

type CheckoutConfirmationProps = {
  sanityCheckoutConfirmation: {
    _id: string;
    banner: {
      heading: string;
      subHeading?: string;
      contentAlignment: string;
      _rawHeroImage: ImageInterface;
      _rawHeroVideo: any;
      primaryColor: SanityColorList;
    };
    seo: Seo;
    slug: {
      current: string;
    };
  };
  sanitySignUp: {
    _rawNewsLetterSubscriptionText: PortableText;
  };
} & LocalizedContextInterface;

type CheckoutConfirmationContext = LocalisedPageContext;

export const query = graphql`
  query ($_id: String, $language: String) {
    sanityCheckoutConfirmation(_id: { eq: $_id }) {
      _id
      banner {
        heading
        subHeading
        primaryColor {
          value
          title
        }
        contentAlignment
        isVideoBanner
        _rawHeroImage(resolveReferences: { maxDepth: 10 })
        heroImage {
          alt
          ...ImageWithPreview
        }
        _rawHeroVideo(resolveReferences: { maxDepth: 10 })
      }
      slug {
        current
      }
      seo {
        metaTitle
        metaDescription
      }
    }
    sanityLogin(i18n_lang: { eq: $language }) {
      _id
      slug {
        current
      }
    }
    sanitySignUp(i18n_lang: { eq: $language }) {
      _id
      _rawNewsLetterSubscriptionText
      slug {
        current
      }
    }
    ...LocalizedContext
  }
`;

const CheckoutConfirmation = (props: PageProps<CheckoutConfirmationProps, CheckoutConfirmationContext>) => {
  const language = props.pageContext.language;
  const checkoutConfirmation = props.data.sanityCheckoutConfirmation;
  const sanityLogin = props.data.sanityLogin;
  const { htmlLang, siteUrl } = useSiteMetadata(language);
  const [isGuestUser, setIsGuestUser] = useState(false);
  const [orderData, setOrderData] = useState<any>({});
  const [noOrderData, setNoOrderData] = useState<any>(false);
  const [formData, setFormData] = useState<any>({});
  const pageTitle = checkoutConfirmation.banner.heading;
  const localizedPath = withPrefix(getLocalizedPath(checkoutConfirmation.slug?.current, checkoutConfirmation._id));

  useEffect(() => {
    const params = Object.fromEntries(new URLSearchParams(window.location.search));
    setIsGuestUser(!!params.isGuest);
    orderStatus(params)
      .then((res: any) => {
        const data = {
          firstName: {
            value: res.data.order.billing.billingAddress.address.firstName,
            disabled: true
          },
          lastName: {
            value: res.data.order.billing.billingAddress.address.lastName,
            disabled: true
          },
          phone: {
            value: res.data.order.billing.billingAddress.address.phone.replace(/\D/g, ""),
            disabled: true
          },
          email: {
            value: res.data.order.orderEmail,
            disabled: true
          },
          confirmEmail: {
            value: res.data.order.orderEmail,
            disabled: true
          }
        };
        setOrderData(res.data.order);
        setFormData(data);
      })
      .catch(error => {
        console.log(error);
        setNoOrderData(true);
      })
      .finally(() => {
        deleteLocalStorageOrderItems();
      });
  }, []);

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={pageTitle}
      pageType={"about"}
      analyticsTagMeta={{ promoId: "11" }}
    >
      <StructuredData
        type={"AboutPage"}
        data={checkoutConfirmation.banner._rawHeroImage}
        language={htmlLang}
        slug={`/${checkoutConfirmation.slug.current}/`}
      />
      <Helmet>
        <link rel="canonical" href={`${siteUrl}${localizedPath}`} />
      </Helmet>
      <SEO
        title={checkoutConfirmation.seo.metaTitle}
        description={checkoutConfirmation.seo.metaDescription}
        imageUrl={checkoutConfirmation.banner._rawHeroImage.asset.url}
        imageAlt={checkoutConfirmation.banner._rawHeroImage.alt}
      />
      <div className="page_about-us">
        <HeroBanner
          image={checkoutConfirmation.banner?._rawHeroImage}
          sanityImage={checkoutConfirmation.banner?.heroImage}
          title={checkoutConfirmation.banner?.heading}
          alignment={checkoutConfirmation.banner?.contentAlignment}
          isVideoBanner={checkoutConfirmation.banner?.isVideoBanner}
          video={checkoutConfirmation.banner?._rawHeroVideo}
        />
        <Section style={{ padding: 0 }}>
          {noOrderData ? (
            <Section title="Please check order data" description="The information provided was probably incorrect">
              <Link className="smt-48" to="/" asButton="nonary" label="Continue Shopping" buttonForm="br-50" />
            </Section>
          ) : (
            <div className="grid-row">
              {Object.keys(orderData).length ? (
                <div
                  className={`grid-col grid-col-lg-6 grid-col-xs-12 ${
                    !(isGuestUser && Object.keys(formData).length) ? "grid-col-offset-3" : ""
                  }`}
                >
                  <Section title="Receipt">
                    <ReceiptData orderData={orderData} />
                    <Link className="smt-48" to="/" asButton="nonary" label="Continue Shopping" buttonForm="br-50" />
                  </Section>
                </div>
              ) : null}
              {isGuestUser && Object.keys(formData).length ? (
                <div className="grid-col grid-col-lg-6 grid-col-xs-12">
                  <SignUpForm
                    prefetchData={formData}
                    isfullWidth={true}
                    ctaLabels={props.data.sanityLabels?.ctaLabels}
                    formsLabels={props.data.sanityLabels?.formsLabels}
                    errorMessages={props.data.sanityLabels?.errorMessages}
                    passwordStrength={props.data.sanityLabels?.passwordStrength}
                    sanityLogin={sanityLogin}
                    newsLetterSubscriptionText={props.data.sanitySignUp?._rawNewsLetterSubscriptionText}
                  />
                </div>
              ) : null}
            </div>
          )}
        </Section>
      </div>
    </Layout>
  );
};

export default CheckoutConfirmation;
