import axios from "axios";

export const API_ENDPOINT =
  typeof window !== "undefined" &&
  (window.location.origin === "http://localhost:8000"
    ? `http://localhost:8888/.netlify/functions`
    : window?.location?.origin + "/.netlify/functions");

export const orderStatus = async (orderData: Record<string, unknown>) => {
  try {
    const url = `${API_ENDPOINT}/salesforce-subscription-orders-status`;
    const serverResponse = await axios.post(url, orderData);
    return serverResponse;
  } catch (err) {
    console.log(err);
  }
};
