import React, { FunctionComponent } from "react";
import { dateFormat } from "../../utils/dateFormat";
import { camelCaseTrimming } from "../../utils/camelCaseTrimming";
import "./styles.scss";
import CartItems from "../CartItems";
import { getSFCCImageUrl } from "../../utils/getSFCCImageUrl";

const totalOrder = [
  "subTotal",
  "totalShippingCost",
  "orderLevelDiscountTotal",
  "shippingLevelDiscountTotal",
  "totalTax",
  "grandTotal"
];
const ReceiptData: FunctionComponent<any> = ({ orderData }) => {
  return (
    <div className="c-receipt-data">
      <div className="c-receipt-data__pair">
        <span>Order Number:</span> {orderData.orderNumber}
      </div>
      <div className="c-receipt-data__pair">
        <span>Order Date:</span> {dateFormat(orderData.creationDate)}
      </div>
      <div className="c-receipt-data__pair">
        <span>Order Status:</span> {orderData.orderStatus}
      </div>
      <div className="c-receipt-data__pair smt-24">
        <span>Shipping Address:</span>
        <div>
          {orderData.shipping[0].shippingAddress.firstName} {orderData.shipping[0].shippingAddress.lastName}
          <br />
          {orderData.shipping[0].shippingAddress.address1} {orderData.shipping[0].shippingAddress.address2}
          <br />
          {orderData.shipping[0].shippingAddress.city}, {orderData.shipping[0].shippingAddress.stateCode}{" "}
          {orderData.shipping[0].shippingAddress.postalCode}
          <br />
          {orderData.shipping[0].shippingAddress.phone}
        </div>
      </div>
      <div className="c-receipt-data__pair smt-24">
        <span>Shipping Method:</span>
        <ul className="c-receipt-data__list">
          <li>
            <span>
              {orderData.shipping[0].selectedShippingMethod.displayName} (
              {orderData.shipping[0].selectedShippingMethod.estimatedArrivalTime})
            </span>
            <span>{orderData.shipping[0].selectedShippingMethod.shippingCost}</span>
          </li>
        </ul>
      </div>
      <div className="c-receipt-data__pair smt-24">
        <span>Billing Address:</span>
        <div>
          {orderData.billing.billingAddress.address.firstName} {orderData.billing.billingAddress.address.lastName}
          <br />
          {orderData.billing.billingAddress.address.address1} {orderData.billing.billingAddress.address.address2}
          <br />
          {orderData.billing.billingAddress.address.city}, {orderData.billing.billingAddress.address.stateCode}{" "}
          {orderData.billing.billingAddress.address.postalCode}
          <br />
          {orderData.orderEmail} <br />
          {orderData.billing.billingAddress.address.phone}
        </div>
      </div>
      <div className="c-receipt-data__pair smt-24">
        <span>Payment:</span>
        <div>
          {orderData.billing.payment.selectedPaymentInstruments[0].selectedAdyenPM}
          <br />
          {orderData.billing.payment.selectedPaymentInstruments[0].maskedCreditCardNumber}
        </div>
      </div>
      <div className="c-receipt-data__pair smt-24">
        <span>Items (Total: {orderData.items.totalQuantity}):</span>
        {orderData.items.items.map((item: any, i: number) => (
          <CartItems
            key={i.toString()}
            id={item.id}
            imageUrl={getSFCCImageUrl(item.images.small[0].url)}
            name={item.productName}
            quantity={item.quantity}
            productItemId={item.id}
            basePrice={item.price.list ? item.price.list.value : item.price.sales.value}
            priceAfterItemDiscount={item.price.sales.value * item.quantity}
            priceAfterOrderDiscount={item.price.sales.value}
            price={item.price.list ? item.price.list.value : item.price.sales.value}
            isReadOnly={true}
          />
        ))}
      </div>
      <div className="c-receipt-data__pair smt-24">
        <span>Totals:</span>
        <ul className="c-receipt-data__list c-receipt-data__list--total">
          {totalOrder.map((total, i) => (
            <li key={i}>
              <span>{camelCaseTrimming(total)}</span>
              <span>{orderData.totals[total].formatted || orderData.totals[total]}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ReceiptData;
